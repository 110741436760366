import React from "react";
import _ from "lodash";

export const ALL_MEDICAL_FRANCHISES = [
	{ value: 1000001, label: 'Sul Brasília' },
	{ value: 1000002, label: 'Goiânia' },
	{ value: 1000003, label: 'Bento Gonçalves' },
  { value: 1000004, label: 'Campo Grande' },
  { value: 1000005, label: 'São Paulo' },
  { value: 1000006, label: 'Campos dos Goytacazes - RJ' },
  { value: 1000007, label: 'Norte Brasília' },
  { value: 1000008, label: 'Ribeirão Preto' },
  { value: 1000009, label: 'Salvador' },
  { value: 1000010, label: 'Sorocaba' },
  { value: 1000011, label: 'Ijuí' },
  { value: 1000012, label: 'Biocenter' },
  { value: 1000013, label: 'Vacaria' },
  { value: 1000014, label: 'Vitória' },
  { value: 1000015, label: 'Bom Jesus de Itabapoana' },
  { value: 1000016, label: 'Niterói - RJ' },
  { value: 1000017, label: 'São Luiz do Maranhão' },
  { value: 1000018, label: 'Petrópolis' },
  { value: 1000019, label: 'Cuiabá' },
  { value: 1000020, label: 'Jundiaí' },
  { value: 1000022, label: 'Cuiabá 2' },
  { value: 1000023, label: 'Brasília Érica' },
  { value: 1000025, label: 'União da Vitória' },
  { value: 1000026, label: 'Juiz de Fora' },
  { value: 1000027, label: 'Palmas' },
  { value: 1000028, label: 'Naviraí' },
  { value: 1000029, label: 'Rio de Janeiro' },
  { value: 1000030, label: 'Presidente Dutra' },
  { value: 1000031, label: 'Avaré' },
  { value: 2000000, label: 'Clínica Testes da Haux' },
]

export const ALL_CLINICAL_FRANCHISES = [
	{ value: 29, label: 'Caxias do Sul' },
	{ value: 41, label: 'Florianópolis' },
	{ value: 45, label: 'Porto Alegre' },
]

export const ALL_FRANCHISES = [
	...ALL_CLINICAL_FRANCHISES,
  ...ALL_MEDICAL_FRANCHISES
]

export const getFilteredMedicalFranchises = (franquias: Number[]) => {
	return ALL_MEDICAL_FRANCHISES.filter((f) => franquias.includes(f.value))
}

export const getFanchiseName = (franquiaId: Number) => {
  const franquia = _.find(ALL_FRANCHISES, (f) => f.value === franquiaId)
  return franquia?.label ?? "HAUX"
}

export interface IAddress {
  cep?: string,
  complement?: string,
  state?: string,
  city?: string,
  street?: string,
  number?: string,
  district?: string,
}

export interface IClient {
  _id: string;
  name: string;
  biologicalSex: string;
  address: IAddress;
  isDeleted?: boolean;
  franchise: number;
}

export interface IClient {
  _id: string;
  name: string;
  biologicalSex: string;
  address: IAddress;
  isDeleted?: boolean;
  franchise: number;
}

interface ClientContextInterface {
  saveAnamnesis: Function;
  acceptedChallenges: string[];
  anamnesis: any[];
  setAnamnesisType: Function;
  currentAnamnesis: any;
  client: IClient | any;
  goal: any;
  goalSuggestion: any;
  updateGoal: Function;
  setGoal: Function;
  downloadReport: Function;
  isMaisMulher: boolean;
  setMaisMulher: Function;
  getFilteredMedicalFranchises: Function;
  getFanchiseName: Function;
}

const ClientContext = React.createContext<ClientContextInterface>({
  saveAnamnesis: () => {},
  acceptedChallenges: [],
  anamnesis: [],
  setAnamnesisType: () => {},
  currentAnamnesis: {},
  client: {},
  goal: {},
  goalSuggestion: {},
  updateGoal: () => {},
  setGoal: () => {},
  downloadReport: () => {},
  isMaisMulher: false,
  setMaisMulher: () => {},
  getFilteredMedicalFranchises: () => {},
  getFanchiseName: () => {},
});
export default ClientContext;
