import { createContext, useContext, useEffect, useState } from "react";
import { Column } from "./BoardColumn";
import { v4 as uuidv4 } from "uuid";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: string;
  storageKey?: string;
};

type BoardProviderState = {
  columns: Column[];
  setColumns: (columns: Column[]) => void;
  newColumn: () => void;
};

const initialState = {
  columns: [],
  setColumns: ([]) => null,
  newColumn: () => null,
};

const BoardProviderContext = createContext<BoardProviderState>(initialState);

export function BoardProvider({ children, ...props }: ThemeProviderProps) {
  const [columns, setColumns] = useState<Column[]>([
    {
      id: "todo" as const,
      title: "Todo",
    },
    {
      id: "in-progress" as const,
      title: "In progress",
    },
    {
      id: "done" as const,
      title: "Done",
    },
  ]);

  const newColumn = () => {
    setColumns((cols) => [
      ...cols,
      {
        id: uuidv4(),
        title: "Edite o nome da seção",
      },
    ]);
  };

  const value = {
    columns,
    setColumns,
    newColumn,
  };

  return (
    <BoardProviderContext.Provider {...props} value={value}>
      {children}
    </BoardProviderContext.Provider>
  );
}

export const useBoard = () => {
  const context = useContext(BoardProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
