import { KanbanBoard } from "./components/KanbanBoard";
import { BoardProvider, useBoard } from "./components/board-provider";

function Template() {
  return (
    <>
      <BoardProvider>
        <KanbanBoard />
      </BoardProvider>
    </>
  );
}

export default Template;
