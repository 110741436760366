import React, { useCallback, useEffect, useRef, useState } from "react";
import jsPDF from 'jspdf';
import html2Canvas from "html2canvas";
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/ducks/Auth/selectors";
import SystemContext, { Challenge } from "../../context/SystemContext";
import ClientContext, { getFilteredMedicalFranchises, getFanchiseName } from "context/ClientContext";
import ClientService from "../../services/ClientService";
import AnamnesisService from "../../services/AnamnesisService";
import GoalService from "../../services/GoalService";
import PDFService from "../../services/PDFService";
import ChallengeService from "../../services/ChallengeService";
import FoodsService from "../../services/FoodsService";
import ReportService from "../../services/ReportsService";

import NavBar from "molecules/NavBar";
import Profile from "./profile";
import Search from "./search";
import Form from "./form";
import Resume from "../Resume/new";
import Laudo from "../Laudo";
import Tech from "../Anamnesis/Tech/index";
import Physical from "../Anamnesis/Physical/index";
import Nutri from "../Anamnesis/Nutri/index";
import PlanoAlimentar from "../PlanoAlimentar";
import { PlanoAlimentar as OldPlanoAlimentar } from "../Anamnesis/Nutri/index";
import Med from "../Anamnesis/Med/index";
import MedTemplate from "../Anamnesis/Med/template";
import ExamRequest from "../Anamnesis/others/ExamRequest";
import Training from "../Anamnesis/Training";
import ParQ from "../Anamnesis/ParQ";
import Psico from "../Anamnesis/Psico";
import Challenges from "../Challenges";
import Metas from "../Metas";

import publicIp from "react-public-ip";

// import TechSchema from "../Anamnesis/Tech/schema";

import {
  Container,
  ToggleContainer,
  PageContainer,
  ListAnamnesisContainer,
  ListAnamnesisItem,
  Encontro,
} from "./styles";
import ClientProducts from "./products";
import ClientNewProducts from "./products/new";

const RESUME_ROUTE = "resume";
const TECH_ROUTE = "tech";
const PHYSICAL_ROUTE = "physical";
const MED_ROUTE = "med";
const NUTRI_ROUTE = "nutri";
const PLANO_ALIMENTAR_ROUTE = "planoalimentar";
const TRAINING_ROUTE = "training";
const PSICO_ROUTE = "psico";
const PARQ_ROUTE = "parq";
const CHALLENGES_ROUTE = "challenges";
const GOALS_ROUTE = "goals";

const TITLES = {
  resume: "Resumo",
  planoalimentar: "Plano alimentar",
  old_planoalimentar: "Plano alimentar [Antigo]",
  forensic: "Laudo pericial",
  checkup: "Check-up saúde",
  bariatric: "Bariátrica",
  "post-bariatric": "Pós-bariátrica",
  round: "Round da Equipe",
  goals: "Metas",
  observation: "Observações do Cliente",
}

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
  meetingNumber?: string;
}

interface ClientInterface {
  _id: string;
  franchise: string;
}

interface AnamneseInterface {
  _id: string;
  consultationDate: string;
  finish?: boolean;
  responsibleProfessional: any;
}

const Client: React.FC = () => {
  const { clientId, anamnesisType, anamnesisId, meetingNumber } = useParams<
    ClientParams
  >();
  const user = useSelector(selectUser);
  const [anamnesisList, setAnamnesisList] = useState<AnamneseInterface[]>([]);
  const [reportList, setReportList] = useState<any[]>([]);
  const lastPublishedAnamnesisRef = useRef<AnamneseInterface | undefined>();
  const lastAnamnesisRef = useRef<AnamneseInterface | undefined>();
  const [client, setClient] = useState<ClientInterface | undefined>(undefined);
  const [goal, setGoal] = useState({});
  const [goalSuggestion, setGoalSuggestion] = useState({});
  const [, setSaving] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [foods, setFoods] = useState([]);
  const [meals, setMeals] = useState([]);
  const [isMaisMulher, setMaisMulher] = useState(false);
  const [acceptedChallenges, setAcceptedChallenges] = useState<Challenge[]>([]);

  const getClient = useCallback(async () => {
    if (clientId) {
      try {
        const response = await ClientService.get(clientId);
        const client = _.get(response, ["data", "data"]);
        return setClient(client);
      } catch (e) {}
    } else {
      return setClient(undefined);
    }
  }, [clientId, setClient]);

  const getAnamnesisList = useCallback(async () => {
    if (location.pathname.includes('client/template')) {
      return
    }
  
    try {
      Swal.fire({
				title: 'Obtendo dados',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			});
      const ipv4 = await publicIp.v4() || "";
      if (anamnesisType === "med" && user.ip && user.ip !== ipv4) {
        NotificationManager.error(
          "A ficha Med só pode ser acessada dentro da clínica",
          'Erro de permissão',
        );
        var err = "Permissão";
        throw err;
      }
      const response = await AnamnesisService.getAllAnamnesis(
        anamnesisType,
        clientId
      );
      console.log("RESP",response)
      let list = _.get(response, ["data", "data", "items"]);
      list = _.orderBy(
        list,
        (o: any) => {
          return moment(o.consultationDate, "DD/MM/YYYY").isValid() &&
            o.consultationDate.length === 10
            ? [moment(o.consultationDate, "DD/MM/YYYY").format("YYYYMMDD"), o.createdAt]
            : [moment().format("YYYYMMDD"), o.createdAt];
        },
        ["asc", "asc"]
      );
      console.log("LIST",list)
      setAnamnesisList(list);
      lastPublishedAnamnesisRef.current = _.last(list.filter((a) => a.finish));
      lastAnamnesisRef.current = _.last(list);
      Swal.close();
    } catch (e) {
      console.log(e);
      Swal.fire('Erro ao obter lista de anamnesis', '', 'error')
      setAnamnesisList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, anamnesisType, setAnamnesisList, meetingNumber]);

  const createNewAnamneses = useCallback(
    async (createNewAnamnesesEmpty = false) => {
      let data: any = {
        client: clientId,
        consultationDate: moment().format("DD/MM/YYYY"),
        responsibleProfessional: user._id
      };

      data =
        anamnesisType === "tech"
          ? {
            ...lastPublishedAnamnesisRef.current,
            _id: undefined,
            finish: false,
            ...data,
            stykuMeasure: undefined,
            bodyComposition: {
              height: _.get(lastPublishedAnamnesisRef.current, [
                "bodyComposition",
                "height",
              ]),
              references: _.get(lastPublishedAnamnesisRef.current, [
                "bodyComposition",
                "references",
              ]),
            },
          }
          : anamnesisType === "med" || anamnesisType === "psico" || anamnesisType === "nutri"
          ? {
              ...(createNewAnamnesesEmpty === true
                ? {}
                : anamnesisType === "psico" ? lastAnamnesisRef.current : lastPublishedAnamnesisRef.current),
              _id: null,
              finish: false,
              ...data,
            }
          : data;
      setSaving(true);
      try {
        await AnamnesisService.insertAnamnesis(anamnesisType, data);
      } catch (e) {
        NotificationManager.error(
          `${anamnesisType}`,
          "Erro ao salvar ficha de anamnese"
        );
      }
      setSaving(false);
      getAnamnesisList();
    },
    [anamnesisType, clientId, setSaving, getAnamnesisList]
  );

  const deleteAnamneses = useCallback(
    async (anamneseId: string) => {
      try {
        await AnamnesisService.deleteAnamnesis(anamnesisType, anamneseId);
        Swal.fire("Excluído!", "Rascunho excluído com sucesso!", "success");
        getAnamnesisList();
      } catch (e) {
        Swal.fire("Erro!", "Não foi possível excluir o rascunho.", "error");
      }
    },
    [anamnesisType, getAnamnesisList]
  );

  const updateGoal = async (values) => {
    const {
      leisureTimeActivity,
      workActivity,
      weight,
      goalWeight,
      height,
      imc,
    } = values;

    try {
    } catch (e) {}
    const response = await GoalService.calculate(
      clientId,
      leisureTimeActivity,
      workActivity,
      weight,
      goalWeight,
      height,
      imc
    );
    const goalSuggestion = response.data.data;
    setGoalSuggestion(goalSuggestion);
  };

  const setClientGoal = async (data) => {
    try {
    } catch (e) {}
    await GoalService.update(data._id, data);
  };

  const getGoal = useCallback(async () => {
    try {
      const response = await GoalService.getLast(clientId);
      const data = _.get(response, ["data", "data"], {});
      setGoal(data);
    } catch (e) {}
  }, [clientId, setGoal]);

  const downloadReport = useCallback(async (template) => {
    Swal.fire({
      title: 'Obtendo dados',
      text: 'Em progresso...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    try {
      const response = await PDFService.generateReport(
        clientId, anamnesisId,
        template ?? ([1000001].includes(client?.franchise) ? 'reportTemplateMin' : 'reportTemplate'));

        if (template == 'reportTemplateMin') {
          try {
            const url =  response;
            //@ts-ignore
            const pdfWindow = window.open();
            pdfWindow.location.href = url; 
            NotificationManager.success(
            "Download feito com sucesso"
            );
          } catch (errors) {
            NotificationManager.error(
              "Erro ao baixar laudo"
            );
          }
        } else {
          var file = new Blob([response.data], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      
    } catch (e) {}
    Swal.close()
  }, [clientId, user, anamnesisId]);

  const getReportList = useCallback(async () => {
    try {
      const response = await ReportService.getAll(clientId, anamnesisType)
      let items = _.get(response, ["data", "data", "items"])
      if (anamnesisType === "observation") {
        if (!items || items.length == 0) {
          createReport()
        } else {
          const [first] = items;

          history.replace(`/client/${clientId}/${anamnesisType}/${first._id}`);
        }
      } else {
        setReportList(items)
      }
    } catch {
      Swal.fire("Erro!", "Erro ao obter lista", "error");
    }
  }, [clientId, anamnesisType, setReportList]);

  const createReport = useCallback(async () => {
    setSaving(true)
    try {
      await ReportService.insert({
        client: clientId,
        type: anamnesisType,
        version: 1,
      })
    } catch {
      Swal.fire("Erro!", "Erro ao criar", "error");
    }
    setSaving(false)
    getReportList()
  }, [clientId, anamnesisType, getReportList]);

  const deleteReport = useCallback(
    async (reportId: string) => {
      try {
        await ReportService.delete(reportId);
        Swal.fire("Excluído!", "Excluído com sucesso!", "success");
        getReportList();
      } catch (e) {
        Swal.fire("Erro!", "Não foi possível excluir.", "error");
      }
    },
    [getReportList]
  );

  const isAnamnesis = useCallback(() => {
    return (
      anamnesisType &&
      ["tech", "nutri", "med", "psico", "physical"].indexOf(anamnesisType) >= 0
    );
  }, [anamnesisType]);

  const isReport = useCallback(() => {
    return (
      anamnesisType &&
      ["forensic", "bariatric", "post-bariatric", "checkup", "round", "observation"].indexOf(anamnesisType) >= 0
    );
  }, [anamnesisType]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  useEffect(() => {
    getGoal();
  }, [getGoal]);

  useEffect(() => {
    if (isAnamnesis()) {
      getAnamnesisList();
    }
    if(isReport()) {
      getReportList()
    }
  }, [isAnamnesis, getAnamnesisList, isReport, getReportList]);

  useEffect(() => {
    async function getAcceptedChallenges() {
      try {
        const response = await ChallengeService.getAccepted(clientId);
        const accepted = _.get(response, ["data", "data"], []);
        setAcceptedChallenges(accepted.map((c) => c.challenge._id));
      } catch (e) {
        setAcceptedChallenges([]);
      }
    }

    getAcceptedChallenges();
  }, [clientId, setAcceptedChallenges]);

  useEffect(() => {
    async function getChallenges() {
      try {
        const response = await ChallengeService.getAll();
        const challenges = _.get(response, ["data", "data", "items"], []);
        setChallenges(challenges);
      } catch (e) {
        setChallenges([]);
      }
    }

    async function getFoods() {
      try {
        const response = await FoodsService.getFoods();
        const foods = _.get(response, ["data", "data"], []);
        setFoods(foods);
      } catch (e) {
        setFoods([]);
      }
    }

    async function getMeals() {
      try {
        const response = await FoodsService.getMeals();
        const meals = _.get(response, ["data", "data"], []);
        setMeals(meals);
      } catch (e) {
        setMeals([]);
      }
    }

    getChallenges();
    getFoods();
    getMeals();
  }, [setChallenges, setFoods, setMeals]);

  const gotToAnamnesis = (anamnese: AnamneseInterface, number: number) => {
    history.push(`${anamnesisType}/${anamnese._id}/${number}`);
  };

  const renderReportList = () => (
    <ListAnamnesisContainer>
        {reportList.map((report, index) => (
          <div key={report._id} style={{ position: "relative" }}>
            <ListAnamnesisItem key={report.id}
              onClick={() => {
                history.replace(`${anamnesisType}/${report._id}`);
              }}
            >
              <Encontro style={{ backgroundColor: `var(--tech)` }}>
                {index + 1}
              </Encontro>
            </ListAnamnesisItem>
            <button
            style={{
              position: "absolute",
              top: 0,
              right: 20,
              bottom: 0,
              color: "var(--danger)",
              fontFamily: "var(--font-heavy)",
            }}
            onClick={() => {
              Swal.fire({
                title: "Deseja realmente excluir?",
                text: "Você não poderá desfazer esta ação!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "var(--success)",
                cancelButtonColor: "var(--danger)",
                confirmButtonText: "Sim, excluir!",
                cancelButtonText: "Desistir",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteReport(report._id);
                }
              });
            }}
          >
            Excluir
          </button>
        </div>
        ))}
        <ListAnamnesisItem onClick={createReport}>
          <strong style={{ color: `var(--${anamnesisType})` }}>
            + Criar novo
          </strong>
        </ListAnamnesisItem>
    </ListAnamnesisContainer>
  );

  const renderList = () => (
    <ListAnamnesisContainer>
      {anamnesisList.map((anamnese, index) => (
        <div key={anamnese._id} style={{ position: "relative" }}>
          <ListAnamnesisItem
            onClick={() => {
              gotToAnamnesis(anamnese, index + 1);
            }}
          >
            <Encontro style={{ backgroundColor: `var(--${anamnesisType})` }}>
              {index + 1}
            </Encontro>
            {anamnese.consultationDate}
            <small style={{ marginLeft: 30 }}>
              {anamnese.finish ? "Publicada" : "Rascunho"}
            </small>
            <strong style={{ marginLeft: 30 }}>
              {!_.isEmpty(anamnese.responsibleProfessional) && `Atendido por: ${anamnese.responsibleProfessional.name}`}
            </strong>
          </ListAnamnesisItem>
          {!anamnese.finish && (
            <button
              style={{
                position: "absolute",
                top: 0,
                right: 20,
                bottom: 0,
                color: "var(--danger)",
                fontFamily: "var(--font-heavy)",
              }}
              onClick={() => {
                Swal.fire({
                  title: "Deseja realmente excluir?",
                  text: "Você não poderá desfazer esta ação!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "var(--success)",
                  confirmButtonText: "Sim, excluir!",
                  cancelButtonText: "Desistir",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteAnamneses(anamnese._id);
                  }
                });
              }}
            >
              Excluir
            </button>
          )}
        </div>
      ))}
      <ListAnamnesisItem
        onClick={() => {
          let createNewAnamnesesEmpty: boolean;

          if (anamnesisType === "med" || anamnesisType === "psico" || anamnesisType === "nutri") {
            Swal.fire({
              title: "Deseja copiar os dados da anamnese anterior?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "var(--success)",
              confirmButtonText: "Sim, copiar!",
              cancelButtonText: "Não!",
            }).then((result) => {
              if (result.isConfirmed) {
                createNewAnamneses();
              } else {
                createNewAnamnesesEmpty = true;
                createNewAnamneses(createNewAnamnesesEmpty);
              }
            });
          } else {
            createNewAnamneses();
          }
        }}
      >
        <strong style={{ color: `var(--${anamnesisType})` }}>
          + Criar nova anamnese
        </strong>
      </ListAnamnesisItem>
    </ListAnamnesisContainer>
  );

  return (
    <SystemContext.Provider
      value={{
        challenges: challenges.filter((c) => c.type !== "step"),
        stepChallenges: challenges.filter((c) => c.type === "step"),
        foods,
        meals,
      }}
    >
      <ClientContext.Provider
        value={{
          client,
          goal,
          goalSuggestion,
          updateGoal,
          setGoal: setClientGoal,
          downloadReport,
          //@ts-ignore
          acceptedChallenges,
          isMaisMulher: isMaisMulher,
          setMaisMulher: setMaisMulher,
          getFanchiseName,
          getFilteredMedicalFranchises
        }}
      >
        <NotificationContainer></NotificationContainer>
        <Container
          style={{
            backgroundColor: "var(--neutral-color)"
          }}
        >
          <ToggleContainer toggled={true}>
            <Search visible={_.isEmpty(client)} />
            <Profile visible={!_.isEmpty(client)} />
          </ToggleContainer>
          <PageContainer>
            <NavBar />
            {anamnesisType && !location.pathname.includes('client/form') && !location.pathname.includes('client/products') && <div className="p-4 pl-8 m-4 rounded-lg bg-[#F6F6F6] text-[#3D2E61]">
              <h1 className="text-4xl capitalize" style={{
                fontFamily: "var(--font-heavy)",
                color: `var(--${anamnesisType})`
              }}>
                {TITLES[anamnesisType] || anamnesisType}
              </h1>
            </div>}
            {(location.pathname.includes('client/template')) && (
              <Switch>
                <Route
                  path={`/client/template/med`}
                  component={MedTemplate}
                />
               </Switch>
            )}
            {(location.pathname.includes('client/form') || location.pathname.includes('client/products')) && (user.profileType.includes('Medical') || (user.franchisesExternal.filter((f) => f > 1000000).length > 0)) && (
              <Switch>
                <Route
                  path={`/client/form/:clientId?`}
                  component={Form}
                />
                <Route
                  path={`/client/products/:clientId?`}
                  exact
                  component={ClientProducts}
                />
                <Route
                  path={`/client/products/:clientId?/new`}
                  component={ClientNewProducts}
                />
               </Switch>
            )}
            {(_.isEmpty(client) && !location.pathname.includes('client/form') && !location.pathname.includes('client/products') && !location.pathname.includes('client/template')) && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  fontFamily: "var(--font-heavy)",
                  fontSize: 16,
                  color: "var(--text)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Selecione um paciente ao lado
              </div>
            )}
            {!_.isEmpty(client) && (
              <>
                {isReport() && _.isEmpty(anamnesisId) && renderReportList()}
                {isAnamnesis() && _.isEmpty(anamnesisId) ? (
                  renderList()
                ) : (
                  <div
                    style={{
                      padding: 54,
                      position: "relative",
                      maxWidth: 1100,
                      margin: "auto",
                    }}
                  >
                     <Switch>
                      <Route
                        path={`/client/:clientId?/${RESUME_ROUTE}/:anamnesisId?`}
                        component={Resume}
                      />
                      <Route
                        path={`/client/:clientId?/${CHALLENGES_ROUTE}/:anamnesisId?`}
                        component={Challenges}
                      />
                      <Route
                        path={`/client/:clientId?/${GOALS_ROUTE}/:anamnesisId?`}
                        component={Metas}
                      />
                      <Route
                        path={`/client/:clientId?/${TECH_ROUTE}/:anamnesisId?/:meetingNumber?`}
                        component={Tech}
                      />
                      <Route
                        path={`/client/:clientId?/${PHYSICAL_ROUTE}/:anamnesisId?/:meetingNumber?`}
                        component={Physical}
                      />
                      <Route
                        path={`/client/:clientId?/${MED_ROUTE}/:anamnesisId?/:meetingNumber?`}
                        exact
                        component={Med}
                      />
                      <Route
                        path={`/client/:clientId?/${MED_ROUTE}/exams`}
                        component={ExamRequest}
                      />
                      <Route
                        path={`/client/:clientId?/${NUTRI_ROUTE}/:anamnesisId?/:meetingNumber?`}
                        component={Nutri}
                      />
                      <Route
                        path={`/client/:clientId?/${TRAINING_ROUTE}`}
                        component={Training}
                      />
                      <Route
                        path={`/client/:clientId?/${PARQ_ROUTE}`}
                        component={ParQ}
                      />
                      <Route
                        path={`/client/:clientId?/${PLANO_ALIMENTAR_ROUTE}/:planId?`}
                        component={PlanoAlimentar}
                      />
                      <Route
                        path={`/client/:clientId?/old_${PLANO_ALIMENTAR_ROUTE}`}
                        component={OldPlanoAlimentar}
                      />
                      <Route
                        path={`/client/:clientId?/${PSICO_ROUTE}/:anamnesisId?/:meetingNumber?`}
                        component={Psico}
                      />
                      {isReport() && anamnesisId && <Route
                        path={`/client/:clientId?/:reportType?/:reportId?`}
                        component={Laudo}
                      />}
                    </Switch>
                  </div>
                )}
              </>
            )}
          </PageContainer>
        </Container>
      </ClientContext.Provider>
    </SystemContext.Provider>
  );
};

export default Client;
