import React, { useContext, useCallback, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUser } from "redux/ducks/Auth/selectors";
import { useQuery } from '@tanstack/react-query';
import NavBarItem from "atoms/NavBarItem";
import ClientContext from "context/ClientContext";
import DiaryService from "services/Diary";
import NotificationsService, { INotification } from "services/Notifications";

import { DiaryComments, IMeal } from 'pages/PlanoAlimentar/diary';
import moment from "moment";
import _ from "lodash";

const NavBar: React.FC<{}> = (props) => {
    const history = useHistory();
    const user = useSelector(selectUser);
    const { client } = useContext(ClientContext);
    const { isLoading, isError, data: notifications, refetch } = useQuery<INotification[], Error>({queryKey: ['query-notifications'], queryFn: NotificationsService.getNotifications})
    const [diaryMeal, setDiaryMeal] = useState<IMeal | undefined>();
    const [notification, setCurrentNotification] = useState<INotification | undefined>();

    const getClientPath = useCallback((subpath: string) => {
        if(client && client._id) {
            return `/client/${client._id}/${subpath}`;
        }
       
        return '/client';
    }, [client])

    const getAnamnesesNavList = useCallback(() => {
        var list: { title: string; url?: string}[] = [];

        if (user.profileType !== "MedicalCoordinator") {
            list.push({ title: "Tech", url: `${getClientPath("tech")}`})
            list.push({ title: "Educação Física", url: `${getClientPath("physical")}`})
            list.push({ title: "Training", url: `${getClientPath("training")}`})
        } else {
            list.push({ title: "Tech", url: `${getClientPath("tech")}`})
            list.push({ title: "Educação Física", url: `${getClientPath("physical")}`})
            list.push({ title: "Nutri", url: `${getClientPath("nutri")}`})
            list.push({ title: "Training", url: `${getClientPath("training")}`})
        }

        if(!user.profileType.includes("Medical")) { 
            list.push({ title: "Nutri", url: `${getClientPath("nutri")}`})
            list.push({ title: "Training", url: `${getClientPath("training")}`})
        }

        if (user.profileType === 'Admin' || user.profileType === 'Superadmin' || user.profileType === 'Medical' || user.profileType === 'Doctor') {
            list.push({ title: "Nutri", url: `${getClientPath("nutri")}`})
            list.push({ title: "Educação Física", url: `${getClientPath("physical")}`})
        }
        if (user.profileType === 'Admin' || user.profileType === 'Superadmin' || user.profileType === 'Medical' || user.profileType === 'Doctor') {
            list.push({ title: "Med", url: `${getClientPath("med")}`})
        }
        if (user.profileType === 'Admin' || user.profileType === 'Superadmin' || user.profileType === 'Medical' || user.profileType === 'Psycologist') {
            list.push({ title: "Psico", url: `${getClientPath("psico")}`},)
        }

        list = _.uniqBy(list, function (e) {
            return e.title;
        })

        return list
    }, [getClientPath, user]);

    const updateDiaryMeal = async(clientId: string, date: string, hour: string) => {
        Swal.fire({
            title: 'Obtendo dados',
            text: 'Em progresso...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        var response;
        if (clientId) {
            response = await DiaryService.getMealByDateAndHour(clientId, date, hour);
        } else {
            response = await DiaryService.getMealById(diaryMeal?._id, diaryMeal?.client);
        }

        const data = _.get(response, ["data", "data"]);
        setDiaryMeal(data)
        Swal.close();
    }

    const openNotification = async (notification: INotification) => {
        if (notification.type === 'Nutri') {
            let dateAndHour = notification.message.slice(notification.message.length - 16);
            var [date, hour] = dateAndHour.split(" ");
            date = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
            try {
                setCurrentNotification(notification)
                await updateDiaryMeal(notification.client._id, date, hour)
                await NotificationsService.read(notification._id)
                refetch()
            } catch(e) {
                Swal.fire({
                    title: 'Erro',
                    text: 'Não foi possível obter os dados',
                    timerProgressBar: true,
                    icon: 'error',
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
        } else if (notification.type === 'Doctor') {
            await NotificationsService.read(notification._id)
            refetch()
            history.push(`/client/${notification.client._id}`)
        }
    }

    return (
        <nav className="relative flex flex-wrap items-center justify-between px-2 bg-[#3D2E61] mb-3 h-20">
            <div className="container mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                    {client && (
                        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                            <NavBarItem
                                title="Resumo"
                                url={`${getClientPath("resume")}`}
                            />
                            {<NavBarItem
                                title="Anamneses"
                                options={getAnamnesesNavList()}
                            />}
                            {!["MedicalNurse"].includes(user.profileType) && 
                            <>
                            <NavBarItem
                                title="Plano alimentar"
                                url={`${getClientPath("planoalimentar")}`}
                            />
                            <NavBarItem
                                title="Laudos"
                                options={[
                                    { title: "Laudo Pericial", url: `${getClientPath("forensic")}`},
                                    { title: "Check-up saúde", url: `${getClientPath("checkup")}`},
                                    { title: "Bariátrica", url: `${getClientPath("bariatric")}`},
                                    { title: "Pós-bariátrica", url: `${getClientPath("post-bariatric")}`},
                                    { title: "Round da Equipe", url: `${getClientPath("round")}`},
                                ]}
                            />
                            <NavBarItem
                                title="Outros"
                                options={[
                                    { title: "Desafios", url: `${getClientPath("challenges")}`},
                                    { title: "Metas", url: `${getClientPath("goals")}`},
                                    { title: "PARQ", url: `${getClientPath("parq")}`},
                                    { title: "Observações do Cliente", url: `${getClientPath("observation")}`},
                                ]}
                            />
                            </>
                            }
                            
                        </ul>
                    )}
                </div>
                <div className= "flex flex-grow items-center">
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                        {user.profileType ==='Nutri' && <NavBarItem
                            title="Chat"
                            url={"/chat"}
                        />}
                        {/* {(user.profileType === "Med" || user.profileType === "Admin" || user.profileType === "Superadmin") && (
                            <NavBarItem
                                title="Template MED"
                                url={"/client/template/med"}
                            />
                        )} */}
                        <NavBarItem
                            title="Notificações"
                            notifications={notifications}
                            error={isError ? 'Erro inesperado' : undefined}
                            loading={isLoading}
                            onClickNotification={openNotification}
                        />
                        <DiaryComments
                            meal={diaryMeal}
                            title={notification?.message}
                            onClose={() => {
                                setDiaryMeal(undefined)
                                setCurrentNotification(undefined)
                            }}
                            onSave={updateDiaryMeal}
                        />
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;